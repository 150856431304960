.GradientText {
  background: linear-gradient(
    90deg,
    rgb(51, 153, 255) 5%,
    rgb(51, 153, 255) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

a {
  text-decoration: none;
}

.LandingBackground {
  --grid-color: hsla(210, 100%, 30%, 0.125);
  --grid-size: 39px;

  background: linear-gradient(
      180deg,
      hsla(210, 100%, 50%, 0.0175),
      #0f1214 85%
    ),
    repeating-linear-gradient(
      180deg,
      transparent 0px,
      transparent calc(var(--grid-size) - 1px),
      var(--grid-color) calc(var(--grid-size) - 1px),
      var(--grid-color) var(--grid-size)
    ),
    repeating-linear-gradient(
      90deg,
      transparent 0px,
      transparent calc(var(--grid-size) - 1px),
      var(--grid-color) calc(var(--grid-size) - 1px),
      var(--grid-color) var(--grid-size)
    );
  background-position: center top;
  background-blend-mode: darken;

  background-size:
    auto 100%,
    var(--grid-size) var(--grid-size),
    var(--grid-size) var(--grid-size);

  &.LandingBackgroundCenter {
    background: radial-gradient(
        circle,
        hsla(210, 100%, 50%, 0.015),
        rgb(15, 18, 20) 65%
      ),
      repeating-linear-gradient(
        0deg,
        transparent 0px,
        transparent calc(var(--grid-size) - 1px),
        var(--grid-color) calc(var(--grid-size) - 1px),
        var(--grid-color) var(--grid-size)
      ),
      repeating-linear-gradient(
        90deg,
        transparent 0px,
        transparent calc(var(--grid-size) - 1px),
        var(--grid-color) calc(var(--grid-size) - 1px),
        var(--grid-color) var(--grid-size)
      );

    background-position: center center;
    background-size:
      auto 100%,
      var(--grid-size) var(--grid-size),
      var(--grid-size) var(--grid-size);
  }
}

.LandingGradient {
  background: linear-gradient(
    45deg,
    hsla(210, 100%, 20%, 0.05),
    rgba(15, 18, 20, 0.5) 65%
  );
}

.LandingGradientBottom {
  background: linear-gradient(
    45deg,
    hsla(210, 100%, 40%, 0.05),
    rgba(15, 18, 20, 0.5) 65%
  );
}

.HeadingScrollMargin {
  scroll-margin-top: 25px;
  scroll-snap-margin-top: 25px; /* iOS 11 and older */
}

.MarkdownBold {
  color: white;
}

.ast-highlight {
  background-color: hsla(210, 100%, 60%, 0.2);
  /* color: hsl(210, 100%, 60%) !important; */
}

.monaco-editor {
  outline: none !important;
}

.hidden {
  display: none !important;
}

.blinking {
  opacity: 1;
  animation: blinkOpacity 0.8s infinite;
}

@keyframes blinkOpacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.monaco-editor .ghost-text {
  color: #999;
  opacity: 0.6;
}
